import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { UserListGame } from "../Components/UserList/UserListGame";
import { WheelButtons } from "../Components/UserList/WheelButtons";
import axios from 'axios';
import { AwardContainer } from "../Components/Awards/AwardContainer";
import RatingSurvey from "../Components/UserList/RatingSurvey";
import SteamGrab from "../Components/UserList/SteamGrab";
import { MenuItem, Select } from "@mui/material";

export const UserLists = ({userData, onUserListSave,  onUserListDel, onUserListUpdate, events, onEventSave}) => {
    const {state} = useLocation();
    const [category, setCategory] = useState(state ? state : 0);
    const [currentTabIndex, setCurrentTabIndex] = useState("1");
    const [currentPageData,setCurrentPageData] = useState(null);
    const [currentPageDetails, setCurrentPageDetails] = useState(null);
    const [currentCompPageDetails, setCompCurrentPageDetails] = useState(null);
    const [compareData,setCompareData] = useState(null);
    const [dataFilter,setDataFilter] = useState("user_rating");
    const [totalData,setTotalData] = useState({tHours:0,aRating:0});
    const [compTotalData,setCompTotalData] = useState({tHours:0,aRating:0});
    const [clicky,setClicky] = useState(false);
    const [err,setErr] = useState(false);
    const hidden = useRef(null);
    const [updating, setUpdating] = useState(false);
    const [gameSearch,setGameSearch] = useState("");
    const [showSurvey,setShowSurvey] = useState(false);
    const [showTooltip,setShowTooltip] = useState(false);
    const [gameshowYear,setGameshowYear] = useState(new Date().getFullYear().toString());
    const [gameshowYears,setGameshowYears] = useState([]);

    useEffect(() => {
        let years = [];
        for(let i = 0; new Date().getFullYear()-i > 2013; i++) {
            years.push((new Date().getFullYear()-i).toString());
        }
        setGameshowYears(years);
        prepTotals(userData);
        if(!localStorage.getItem("userDataLastUpdate") || new Date().getTime() - JSON.parse(localStorage.getItem("userDataLastUpdate")) > 86400000)
            refreshGameData();
    },[userData])

    useEffect(() => {
        if(gameSearch === "") {
            prepData(userData)
            if(compareData)
                prepData(compareData, 1)
        } else {
            let searchFormat = gameSearch.replace(/[!'.,-: ]/g, "").toLowerCase();
            prepData(userData.filter((item)=>item.game.name.toLowerCase().replace(/[!'.,-: ]/g, "").includes(searchFormat) || (item.game.game_modes && item.game.game_modes.some((mode)=> mode.name.toLowerCase().replace(/[!'.,-: ]/g, "").includes(searchFormat))) || (item.game.genres && item.game.genres.some((genre)=> genre.name.toLowerCase().replace(/[!'.,-: ]/g, "").includes(searchFormat))) || (item.onGamePass && "gamepass" == searchFormat) || (item.onEAPlay && "eaplay" == searchFormat) || (item.game.game_status && item.game.game_status.status == "Early Access" && "earlyaccess" == searchFormat)))
            if(compareData)
                prepData(compareData.filter((item)=>item.game.name.toLowerCase().replace(/[!'.,-: ]/g, "").includes(gameSearch.replace(/[!'.,-: ]/g, "").toLowerCase())), 1)
        }
        
    },[category,userData,dataFilter,compareData,gameSearch])

    // Used to get steam app ids for games under watchlist and get the prices for them
    // useEffect(()=>{
    //     if(userData)
    //         axios.get("https://game-cal-cors.onrender.com/https://api.steampowered.com/ISteamApps/GetAppList/v2/")
    //         .then(async (res) => {
    //             console.log(res.data.applist.apps)
    //             let p = []
    //             userData.filter((e)=>e.category === 5).map((e) => {
    //                 let p2 = {}
    //                 let foundGame = res.data.applist.apps.find((e2) => e2.name.replace(/[^\x00-\x7F]/g, "").toLowerCase() === (e.game.name.replace(/[^\x00-\x7F]/g, "").toLowerCase()));
    //                 p2["steamId"] = foundGame && foundGame.appid;
    //                 p2["userDataName"] = e.game.name
    //                 p.push(p2)
    //             })
    //             axios.get(`https://game-cal-cors.onrender.com/https://store.steampowered.com/api/appdetails?appids=${p.filter((e)=>e.steamId).map((e)=>e.steamId)}&filters=price_overview`)
    //             .then((res2) => {
    //                 console.log(res2.data)
    //                 p.map((e) => p["priceData"] = res2.data[`${e.steamId}`].data.price_overview)
    //                 console.log(p)
    //             })
    //         })
    // },[userData])

    const refreshGameData = () => {
        setUpdating(true);
        let currentUserDataLength = userData.length;
        while (currentUserDataLength > 0) {
            let temp = [];
            let search = ``;
            userData.slice((currentUserDataLength-495 > 0) ? currentUserDataLength-495 : 0,currentUserDataLength).map((e) => {
                search === `` ? search = e.game.id.toString() : search += ","+e.game.id.toString();
                temp.push(e)
            })
            currentUserDataLength = currentUserDataLength - 495;
            axios({
                url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
                },
                    data: `fields category,cover.*,first_release_date,game_modes.name,game_status.status,genres.name,name,release_dates.date; limit `+temp.length+`; where id = (`+search+`);`
                })
                .then(response => {
                    let temp = [...userData]
                    let respCopy = response.data.slice()
                    respCopy.map((item) => {
                    let gameIndex = temp.findIndex((e)=> e.game.id === item.id)
                    if(item && !item.first_release_date && item.release_dates)
                        item.first_release_date = item.release_dates[0].date
                    if(item && gameIndex !== -1) {
                        temp[gameIndex].game = item;
                    }
                    })
                    onUserListUpdate(temp)
                    if(currentUserDataLength <= 0) {
                        localStorage.setItem("userDataLastUpdate", new Date().getTime())
                        setUpdating(false);
                    }
                })
                .catch(err => {
                    setUpdating(false);
                    console.error(err);
            });
        }
    }

    const prepTotals = (data,user=0) => {
        let hourVal = 0
        let ratingAvgVal = 0
        let ratingCount = 0
        data.map((e) => {
            hourVal += Number(e.user_hours)
            if(e.user_rating) {
                ratingAvgVal += Number(e.user_rating)
                ratingCount += 1
            }
        })
        if(user === 1) ratingCount !== 0 ? setCompTotalData({tHours:hourVal,aRating:(ratingAvgVal/ratingCount).toFixed(1)}) : setCompTotalData({tHours:hourVal,aRating:"Complete Games and Add Ratings For Average"})
        else ratingCount !== 0 ? setTotalData({tHours:hourVal,aRating:(ratingAvgVal/ratingCount).toFixed(1)}) : setTotalData({tHours:hourVal,aRating:"Complete Games and Add Ratings For Average"})
    }

    const prepData = (data,user=0) => {
        const dataCopy = data.slice().sort((a,b) => {
            if (dataFilter === "user_rating" || dataFilter === "user_hours") {
                if((a[dataFilter]?Number(a[dataFilter]) : 0) > (b[dataFilter]?Number(b[dataFilter]) : 0)) {
                    return -1;
                }
            } else if (dataFilter === "name") {
                if((a["game"][dataFilter]?a["game"][dataFilter] : Number.MAX_VALUE) < (b["game"][dataFilter]?b["game"][dataFilter] : Number.MAX_VALUE)) {
                    return -1;
                }
            } else if (dataFilter === "first_release_date") {
                if((a["game"][dataFilter]?a["game"][dataFilter] : Number.MAX_VALUE) > (b["game"][dataFilter]?b["game"][dataFilter] : Number.MAX_VALUE)) {
                    return -1;
                }
            }
        })
        setCurrentPageData(dataCopy);
        const preppedData = dataCopy.map((e,index) => {
            if (e.category === category) {
                return(
                    user === 1 ? <UserListGame
                        key={index}
                        gameData={e}
                        userData={gameSearch ? userData : data}
                        user={user}
                        onUserListDel={onUserListDel}
                        onUserListSave={onUserListSave}
                        onUserListUpdate={onUserListUpdate}
                    /> : <UserListGame
                    key={index}
                    gameData={e}
                    userData={gameSearch ? userData : data}
                    onUserListDel={onUserListDel}
                    onUserListSave={onUserListSave}
                    onUserListUpdate={onUserListUpdate}
                /> 
                )
        }}
        )
        user === 1 ? setCompCurrentPageDetails(preppedData) : setCurrentPageDetails(preppedData);
    }

    const exportUserInfo = () => {
        const fileData = JSON.stringify(userData);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "user-data.txt";
        link.href = url;
        link.click();
    }

    const importUserInfo = (e) => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            localStorage.setItem('userdata', e.target.result);
            window.location.reload();
        };
    }

    const handleButtonClick = (e) => {
        hidden.current.click();
    }

    const handleYearChange = (e) => {
        setGameshowYear(e.target.value);
    }
    
    const fileCheck = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader()
        if (file.type.match(/text.*/)) {
            setErr(false);
            reader.onload = function (event) {
                setCompareData(JSON.parse(event.target.result));
                prepTotals(JSON.parse(event.target.result),1);
            }
        } else {
           setErr(true)
        }
        reader.readAsText(file);
    }

    const myStyle = (clicky && compareData) ? {justifyContent:"space-evenly",display:"grid",gridTemplateColumns:"auto auto",margin:"auto",textAlign:"center",columnGap:"40%"} : {justifyContent:"space-evenly",display:"grid",gridTemplateColumns:"auto auto",margin:"auto",textAlign:"center"}
    const gridColStyle = currentTabIndex === "3" ? {display:"grid",gridTemplateColumns:"22% auto 0%",columnGap:"1%",marginLeft:"1%",marginTop:"20px",transition:"2s"} : {display:"grid",gridTemplateColumns:"22% auto 21%",columnGap:"2%",marginLeft:"1%",marginRight:"1%",marginTop:"20px",transition:"1.5s"}
    const gameshowYearSelect = <Select value={gameshowYear} onChange={handleYearChange} sx={{color: "white", '.MuiSvgIcon-root ': {fill: "white !important",}}} size="small">
                                    {gameshowYears.map((item,index)=><MenuItem value={item} key={index}>{item}</MenuItem>)}
                                </Select>
    return (
        <>
            <div className="standinBg" />
            {!clicky && <div style={gridColStyle}>
                <iframe width="100%" height="760px" src="https://wheelofnames.com" style={{animation:"fadeIn 1s"}} />
                <div style={{animation:"fadeIn 1s"}}>
                    <TabContext value={currentTabIndex}>
                        <TabList variant="fullWidth" onChange={(e, tabIndex) => setCurrentTabIndex(tabIndex)} sx={{".Mui-selected": {border:"2px solid #a760e9", borderTopRightRadius:"20px", borderTopLeftRadius:"20px", color:"white !important"}}} TabIndicatorProps={{style:{backgroundImage: "linear-gradient(270deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)"}}}>
                            <Tab sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} label="Steam Charts" value="1" />
                            <Tab sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} label={<div style={{display:"flex"}}>{gameshowYearSelect}<div style={{margin:"auto"}}>Gameshow Recap</div></div>} value="2" />
                            <Tab sx={{color:"white", textShadow:"1px 1px 1px #a760e9"}} label="Personal Game Awards" value="3" />
                        </TabList>
                        <TabPanel value="1" style={{padding:"0px",paddingTop:"10px"}}>
                            <iframe width="100%" height="685px" src={"https://steamcharts.com"} />
                        </TabPanel>
                        <TabPanel value="2" style={{padding:"0px",paddingTop:"10px"}}>
                            <iframe width="100%" height="685px" src={Number(gameshowYear) > 2022 ? "https://"+gameshowYear+".gamesrecap.io" : "https://"+gameshowYear+".e3recap.com"} />
                        </TabPanel>
                        <TabPanel value="3" sx={{display:"grid",gridTemplateColumns:"100px auto", height:"700px",padding:"0px",paddingTop:"10px",border:"1px solid #a760e9",backgroundColor:"#181a1b"}}>
                            <AwardContainer userData={userData} onUserListUpdate={onUserListUpdate} />
                        </TabPanel>
                    </TabContext>
                </div>
                {currentTabIndex !== "3" &&
                    <div style={{animation:"fadeIn 1s"}}>
                        <SteamGrab userData={userData} onUserListUpdate={onUserListUpdate} onUserListSave={onUserListSave} onUserListDel={onUserListDel} events={events} onEventSave={onEventSave} />
                    </div>
                }
            </div>
            }
            <div style={{display:"grid",gridTemplateColumns:"22% auto 22%"}}>
                {!clicky ? <WheelButtons userData={currentPageData}/> : <div></div>}
                <div className="ratingTitle" style={myStyle}>
                    <div style={{display:"flex", columnGap:"20px"}}>
                        <div>{totalData.tHours.toFixed(1)}<div>Total Hours</div></div>
                        {!isNaN(totalData.aRating) ? <div>{totalData.aRating} / 10<div>Average Rating</div></div> : <div>{totalData.aRating}</div>}
                    </div>
                    {clicky && compareData && <div style={{display:"flex", columnGap:"20px"}}>
                        <div>{compTotalData.tHours.toFixed(1)}<div>Total Hours</div></div>
                        {!isNaN(compTotalData.aRating) ? <div>{compTotalData.aRating} / 10<div>Average Rating</div></div> : <div>{compTotalData.aRating}</div>}
                    </div>}
                </div>
                <div style={{margin:"auto"}}>
                    <button className="btn btn-primary" onClick={()=>setShowSurvey(true)}>Rating Survey</button>
                    {userData.filter((e)=>e.hltb.length == 0).length > 0 && <button className="btn btn-primary" onClick={()=>console.log(userData.filter((e)=>e.hltb.length == 0))}>Find Broken HLTB</button>}
                    {showSurvey && <RatingSurvey onClose={()=>setShowSurvey(false)} />}
                </div>
            </div>
            <div style={{position:"relative",display:"flex",justifyContent:"center", marginBottom:"10px",gap:"10px"}}>
                <input 
                    type="text" 
                    style={{width:"30%", borderRadius:"20px"}} 
                    placeholder="Search For Games Within Your Lists"
                    value={gameSearch}
                    onChange={(e)=>setGameSearch(e.target.value)}
                     />
                <div style={{backgroundColor:"grey",borderRadius:"15px",color:"white", width:"25px",height:"25px",textAlign:"center",marginLeft:"-39px",marginTop:"2.7px"}} onMouseEnter={()=>setShowTooltip(true)} onMouseLeave={()=>setShowTooltip(false)}>?</div>
                {showTooltip && 
                <div style={{zIndex:"20",backgroundColor: "black",boxShadow:"1px 1px 10px #a760e9",color:"white",textAlign:"center",borderRadius:"10px",width:"300px",height:"280px",position:"absolute",marginTop:"-285px",marginLeft:"26.5%"}}>
                    Additional Search Terms
                    <div className="steamSepLine" style={{backgroundImage: "linear-gradient(270deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)",width:"50%",margin:"auto"}} />
                    <span style={{fontWeight:"600"}}>Release Info:</span> Early Access, Gamepass, Eaplay 
                    <div className="steamSepLine" style={{backgroundImage: "linear-gradient(270deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)",width:"50%",margin:"auto"}} />
                    <span style={{fontWeight:"600"}}>Modes:</span> Singleplayer, Multiplayer, Coop, MMO, Battle Royale, Split Screen 
                    <div className="steamSepLine" style={{backgroundImage: "linear-gradient(270deg, #a760e9 0%, rgba(100, 180, 250) 50%, #a760e9 100%)",width:"50%",margin:"auto"}} />
                    <span style={{fontWeight:"600"}}>Genres:</span> RPG, Shooter, Fighting, Music, Platform, Puzzle, Racing, RTS, Simulator, Sport, Strategy, Turn-based, Tactical, Quiz, Hack and slash, Adventure, Visual Novel, Indie, Card & Board Game, MOBA, Point-and-click
                </div>}
                <button onClick={()=>setGameSearch("")} style={{borderRadius:"20px"}} className="btn btn-danger btn-sm">Clear</button>
            </div>
            <div>
                <div style={{display:"grid",gridTemplateColumns:"22% auto 22%",marginBottom:"20px"}}>
                    <div className="btn-group" role="group" style={{margin:"auto"}} data-toggle="buttons" aria-label="Basic radio toggle button group">
                        <div>
                            <button className="btn btn-primary" style={{borderTopRightRadius:"0",borderBottomRightRadius:"0"}} onClick={handleButtonClick}>Import Data</button>
                            <input type="file" ref={hidden} style={{display:"none"}} onChange={importUserInfo} />
                        </div>
                        <button className="btn btn-primary" onClick={() => {exportUserInfo()}}>Export Lists</button>
                        {!clicky && <button className="btn btn-primary" onClick={()=>{setCompareData(null); setClicky(!clicky);}}>Compare Lists</button>}
                        {clicky && <button className="btn btn-secondary" onClick={()=>setClicky(!clicky)}>Go Back</button>}
                    </div>
                    <div className="btn-group" role="group" style={{margin:"auto"}} data-toggle="buttons" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onClick={()=>setCategory(0)} defaultChecked={true} checked={category == 0} />
                        <label className="btn btn-primary" htmlFor="btnradio1">
                            Currently Playing <span className="uListGameCountBg">{currentPageData && currentPageData.filter((item)=>item && item.category === 0).length}</span>
                        </label>
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onClick={()=>setCategory(1)} autoComplete="off" checked={category == 1} />
                        <label className="btn btn-primary" htmlFor="btnradio2">
                            Planning to Play <span className="uListGameCountBg">{currentPageData && currentPageData.filter((item)=>item && item.category === 1).length}</span>
                        </label>
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onClick={()=>setCategory(3)} autoComplete="off" checked={category == 3} />
                        <label className="btn btn-primary" htmlFor="btnradio3">
                            On Hold <span className="uListGameCountBg">{currentPageData && currentPageData.filter((item)=>item && item.category === 3).length}</span>
                        </label>
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" onClick={()=>setCategory(2)} checked={category == 2} />
                        <label className="btn btn-primary" htmlFor="btnradio4">
                            Completed <span className="uListGameCountBg">{currentPageData && currentPageData.filter((item)=>item && item.category === 2).length}</span>
                        </label>
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" onClick={()=>setCategory(4)} checked={category == 4} />
                        <label className="btn btn-primary" htmlFor="btnradio5">
                            Dropped <span className="uListGameCountBg">{currentPageData && currentPageData.filter((item)=>item && item.category === 4).length}</span>
                        </label>
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio6" autoComplete="off" onClick={()=>setCategory(5)} checked={category == 5} />
                        <label className="btn btn-primary" htmlFor="btnradio6">
                            Watchlist <span className="uListGameCountBg">{currentPageData && currentPageData.filter((item)=>item && item.category === 5).length}</span>
                        </label>
                    </div>
                    <div className="btn-group" role="group" style={{width:"50%",margin:"auto"}} data-toggle="buttons" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradio2" id="filt1" autoComplete="off" onClick={()=>setDataFilter("user_rating")} defaultChecked={true} />
                        <label className="btn btn-primary" htmlFor="filt1">Rating</label>
                        <input type="radio" className="btn-check" name="btnradio2" id="filt2" onClick={()=>setDataFilter("user_hours")} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="filt2">Hours</label>
                        <input type="radio" className="btn-check" name="btnradio2" id="filt3" onClick={()=>setDataFilter("name")} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="filt3">Name</label>
                        <input type="radio" className="btn-check" name="btnradio2" id="filt4" onClick={()=>setDataFilter("first_release_date")} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="filt4">Release</label>
                    </div>
                </div>
                {clicky && <div style={{display:"flex",justifyContent:"space-around",marginBottom:"10px"}}>
                    <div></div>
                    <div></div>
                    <div>
                        {err && <div style={{color:"red"}}>Invalid File Type</div>}
                        <input type="file" onChange={fileCheck} />
                    </div>
                </div>}
                {!updating ?
                    !clicky && <div style={{display:"flex",flexWrap:"wrap",gap:"30px",width:"100%",justifyContent:"center"}}>{currentPageDetails}</div> 
                :
                    <div style={{fontSize:"50px",color:"white",textAlign:"center",textShadow:"2px 2px 2px #a760e9"}}>Updating...</div>
                }
                {clicky && <div style={{display:"grid",gridTemplateColumns:"50% 50%"}}>
                    <div style={{display:"flex",flexWrap:"wrap",gap:"30px",borderRight:"5px solid rgba(100, 180, 250)",justifyContent:"start"}}>{currentPageDetails}</div>
                    <div>
                        {compareData && <div style={{display:"flex",flexWrap:"wrap",gap:"30px",justifyContent:"center"}}>{currentCompPageDetails}</div>}
                    </div>
                </div>}
            </div>
        </>
    )
}

export default UserLists;