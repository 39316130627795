import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import { dataMapping } from '../Hooks/apiDataMapping';
import { getGameData } from '../Hooks/apiData';
import { UserListButtons } from '../Components/UserList/UserListButtons';
import YoutubeEmbed from '../Utils/youtubeEmbed';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Countdown from '../Components/Countdown';

export const GameDetails = ({userData, onUserListDel, onUserListSave, events, onEventSave, onEventDel}) => {
    const {gameId} = useParams();
    const navigate = useNavigate()
    const {details,testRef} = getGameData(gameId.toString());
    const [category,setCategory] = useState(0);
    const [currentPageDetails, setCurrentPageDetails] = useState(null);
    const [hltbHours,setHltbHours] = useState()
    const gamePassData = details && localStorage.getItem("gamePassData") ? JSON.parse(localStorage.getItem("gamePassData")).data.find((e) => e.title.replace(/[^\x00-\x7F]/g, "").toLowerCase().includes(details.name.replace(/[^\x00-\x7F]/g, "").toLowerCase())) : undefined;
    const gameArt = details && details.artworks ? <div style={{position: "absolute", backgroundPosition: "center", backgroundSize: "cover",height: "100%",width:"100%",zIndex:"-2",backgroundImage:`linear-gradient(to top,rgba(0,0,0,1),rgba(0,0,0,0)),url(//images.igdb.com/igdb/image/upload/t_1080p/${details.artworks[0]["image_id"]}.jpg)`}}></div> : <div className="standinBg" />
    const gameBundles = dataMapping(details,"bundles",navigate)
    const gameDlc = dataMapping(details,"dlcs",navigate)
    const gameExpac = dataMapping(details,"expansions",navigate)
    const gameFranchises = dataMapping(details,"franchises",navigate)
    const gameEngines = dataMapping(details,"game_engines",navigate)
    const gameModes = dataMapping(details,"game_modes",navigate)
    const gameGenres = dataMapping(details,"genres",navigate)
    const gameCompanies = dataMapping(details,"involved_companies",navigate)
    const gameKeywords = dataMapping(details,"keywords",navigate)
    const gameMultiModes = details && details.multiplayer_modes ? details.multiplayer_modes[0] : null
    const gameParent = dataMapping(details,"parent_game",navigate)
    const gamePerspectives = dataMapping(details,"player_perspectives",navigate)
    const gameReleaseDates = details && details.release_dates ? details.release_dates.map((e, index) => <tr key={index}><td><a className="gameDInfoLink" onClick={() => navigate("/search-results/"+e.platform.id, {state: {type:"platforms"}})}>{e.platform.name}</a></td><td>|</td><td>{(new Date(e.date*1000).getUTCMonth()+1) +"/"+new Date(e.date*1000).getUTCDate()+"/"+new Date(e.date*1000).getUTCFullYear()}</td></tr>) : <tr><td>No Release Dates or Platforms Known</td></tr>
    const gameRemakes = dataMapping(details,"remakes",navigate)
    const gameRemasters = dataMapping(details,"remasters",navigate)
    const gameScreenshots = details && details.screenshots ? details.screenshots.map((e, index) => <div key={index}><img src={`//images.igdb.com/igdb/image/upload/t_screenshot_huge/${e.image_id}.jpg`}></img></div>) : null
    const gameSimilar = dataMapping(details,"similar_games",navigate)
    const gameStandalone = dataMapping(details,"standalone_expansions",navigate)
    const gameThemes = dataMapping(details,"themes",navigate)
    const gameVideos = details && details.videos ? details.videos.map((e, index) => <div key={index}><YoutubeEmbed embedId={e.video_id} /></div>) : null
    const gameWebsites = dataMapping(details,"websites",navigate)
    const getVideoThumb = (s) => s.embedId ? `https://img.youtube.com/vi/${s.embedId}/default.jpg` : s.src;
    const customRenderThumb = (children) =>
        children.map((item,index) => {
                    return <img key={index} src={getVideoThumb(item.props.children.props)} />
        })

    const saveEvent = (details,date) => {
        const dateChange = `${date.getUTCMonth()+1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
        onEventSave([ ...events, { title: details.name+" Release", game: details, date: dateChange }])
    }
    const delEvent = (title) => { onEventDel(events.filter(e => e.game && e.game.name !== title)) }

    useEffect(() => {
        if(details)
            fetch(`https://game-cal-cors.onrender.com/https://shouldiplay-api.up.railway.app/hltb/${details.name.toLowerCase()}`)
            .then((response) => response.json())
            .then((result) => {
                result.data.map((e)=>{
                    if(e.game_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[!'.,-:]/g, "").replace(/[&]/g, "and") === details.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[!'.,-:]/g, ""))
                        setHltbHours({mainHours:e.comp_main,mainCount:e.comp_main_count,mainExtraHours:e.comp_plus,mainExtraCount:e.comp_plus_count,completionHours:e.comp_100,completionCount:e.comp_100_count,hltbReview:e.review_score,hltbReviewCount:e.count_review})
                })
            }).catch((err) => {
                console.log(err);
                setHltbHours([]);
            });
    },[testRef.current])

    useEffect(() => {
        switch(category) {
            case 0:
                gameBundles ? setCurrentPageDetails(gameBundles) : setCurrentPageDetails([])
                break;
            case 1:
                gameDlc ? setCurrentPageDetails(gameDlc) : setCurrentPageDetails([])
                break;
            case 2:
                (gameExpac && gameStandalone) ? setCurrentPageDetails([...gameExpac,...gameStandalone]) : (gameExpac && !gameStandalone) ? setCurrentPageDetails(gameExpac) : (!gameExpac && gameStandalone) ? setCurrentPageDetails(gameStandalone) : setCurrentPageDetails([])
                break;
            case 3:
                (gameRemakes && gameRemasters) ? setCurrentPageDetails([...gameRemakes,...gameRemasters]) : (gameRemakes && !gameRemasters) ? setCurrentPageDetails(gameRemakes) : (!gameRemakes && gameRemasters) ? setCurrentPageDetails(gameRemasters) : setCurrentPageDetails([])
                break;
        }
    },[category,testRef.current])

    window.onpopstate = () => {
        window.location.reload(false)
    }
    return (
        <>
        {gameArt && <div>{gameArt}</div>}
        {!testRef.current && <div style={{textAlign:"center",color:"white",fontSize:"60px"}}>Loading...</div>}
        {details && <div id="gameD-grid-container">
            <div style={{marginTop:"30px"}}>
                {gameParent && <div><div className="boxTitle">Parent Game</div><div style={{width:"20%",height:"3px",backgroundColor:"gold"}} /><div style={{display:"flex", justifyContent:"center",marginTop:"10px"}}>{gameParent}</div></div>}
                <br/><br/>
                <UserListButtons 
                    game={details}
                    searchBar={false}  
                    onUserListSave={onUserListSave}
                    onUserListDel={onUserListDel}
                    userData={userData}
                    events={events}
                    onEventSave={onEventSave}
                    gamePassData={gamePassData}
                />
                <br /><br />
                <div style={{color:"white",fontSize:"20px", textShadow:"1px 1px 1px #a760e9"}}>Countdown To Release:</div>
                <Countdown selectedEvent={{date:details.first_release_date*1000,game:details}} />
            </div>
            <div style={{backgroundImage:"linear-gradient(to top, rgb(0, 0, 0,0),rgba(0,0,0,0.3), rgba(0,0,0,1), black)",padding:"10px",borderRadius:"10px",fontWeight:"600"}}>
                {details.cover ? 
                    <div className="gameCover">
                        <img className="gameCoverImg" style={{width:"100%"}} src={`//images.igdb.com/igdb/image/upload/t_cover_big/${details.cover.image_id}.jpg`} />
                        <div className="gameCoverName" style={{fontSize: "1.5vw"}}>
                            {gamePassData?.type === "ea" && <div className="thirdPartyBanner" style={{backgroundImage:"linear-gradient(270deg, #e52a35, #0d1041"}}>On EA Play</div>}
                            {gamePassData?.type === "xbox" && <div className="thirdPartyBanner" style={{backgroundColor:"#0e7a0d"}}>On GamePass</div>}
                            {details.name}
                        </div>
                    </div> 
                : 
                    <div className="gameOuterBorder">
                        <div className="gameCover" onClick={() => { navigate("/game-details/"+e.id); window.location.reload(false); }}>
                            <img className="gameCoverImg" style={{width:"100%"}} src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="130px" height="185px" />
                            <div className="gameCoverName" style={{fontSize: "1.5vw"}}>
                                {gamePassData?.type === "ea" && <div className="thirdPartyBanner" style={{backgroundImage:"linear-gradient(270deg, #e52a35, #0d1041"}}>On EA Play</div>}
                                {gamePassData?.type === "xbox" && <div className="thirdPartyBanner" style={{backgroundColor:"#0e7a0d"}}>On GamePass</div>}
                                {details.name}
                            </div>
                        </div>
                    </div>
                }
                {gameCompanies && <div className="gameDInfoBorder">{gameCompanies}</div>}
                {details && details.game_status && (details.game_status.status == "Early Access" || details.game_status.status == "Released") && <div className="gameDInfoBorder" style={{marginTop:"10px"}}>
                    <div style={{fontSize:"25px",textAlign:"center",textShadow:"1px 1px 1px rgba(100, 180, 250)"}}>{details.game_status.status}</div>
                </div>}
                <div className="gameDInfoBorder" style={{marginTop:"10px"}}>
                    <div>Release Dates & Platforms</div>
                    <table><tbody>{gameReleaseDates}</tbody></table>
                    <div style={{textAlign:"center"}}>
                        {details.first_release_date*1000 >= new Date().getTime() && !events.some(eventGame => eventGame.game ? eventGame.game.name === details.name : null) && <button className="btn btn-primary" onClick={() => saveEvent(details,new Date(details.first_release_date*1000))}>Add to Calendar</button>}
                        {events.some(eventGame => eventGame.game ? eventGame.game.name === details.name : null) && <button className="btn btn-danger" onClick={() => delEvent(details.name)}>Remove from Calendar</button>}
                    </div>
                </div>
                {gameEngines && <div className="gameDInfoBorder" style={{marginTop:"10px"}}><div>Game Engine</div>{gameEngines}</div>}
                {(gameFranchises || details.collection) && 
                    <div className="gameDInfoBorder" style={{marginTop:"10px"}}>
                        {gameFranchises && <div>Franchise: {gameFranchises}</div>}
                        {details.collection && <div>Collection: {details.collection.name}</div>}
                    </div>}
                {(gameModes || gameMultiModes) &&
                    <div className="gameDInfoBorder" style={{marginTop:"10px"}}>
                        <div>Game Mode(s)</div>
                        {gameModes && <div>{gameModes}</div>}
                        {gameMultiModes && <div className="dropdown" style={{marginTop:"5px"}}>
                            <button className="btn btn-secondary dropdown-toggle" type="button"  data-bs-toggle="dropdown" aria-expanded="false">Multiplayer Modes</button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item">Campaign Coop: {gameMultiModes.campaigncoop ? "Yes" : "No"} </a>
                                <a className="dropdown-item">Drop in/out: {gameMultiModes.dropin ? "Yes" : "No"}</a>
                                <a className="dropdown-item">LAN Coop: {gameMultiModes.lancoop ? "Yes" : "No"}</a>
                                <a className="dropdown-item">Splitscreen : {gameMultiModes.splitscreen ? "Yes" : "No"}</a>
                                <a className="dropdown-item">Offline Coop: {gameMultiModes.offlinecoop ? "Yes" : "No"}</a>
                                { gameMultiModes.offlinecoop && <a className="dropdown-item">Offline Coop Max Players: {gameMultiModes.offlinecoopmax}</a>}
                                <a className="dropdown-item">Online Coop: {gameMultiModes.onlinecoop ? "Yes" : "No"}</a>
                                { gameMultiModes.onlinecoop && <a className="dropdown-item">Online Coop Max Players: {gameMultiModes.onlinecoopmax}</a>}
                            </div>
                        </div>}
                    </div>}
                {gamePerspectives && <div className="gameDInfoBorder" style={{marginTop:"10px"}}>Player Perspective(s)<div>{gamePerspectives}</div></div>}
                {gameGenres && <div className="gameDInfoBorder" style={{marginTop:"10px"}}>Genre(s)<div>{gameGenres}</div></div>}
                {gameThemes && <div className="gameDInfoBorder" style={{marginTop:"10px"}}>Theme(s)<div>{gameThemes}</div></div>}
                {gameKeywords && <div className="gameDInfoBorder" style={{marginTop:"10px"}}>Keyword(s)<div style={{overflowY:"auto",maxHeight:"200px"}}>{gameKeywords}</div></div>}
            </div>
            <div style={{paddingTop:"35px"}}>
                {hltbHours &&
                    <div className="gameDetailsRH" style={{fontWeight:"600",color:"white",textShadow:"2px 2px 2px black"}}>
                        {(details.aggregated_rating && details.aggregated_rating_count) &&
                            <div className="gameDetailsRO" style={{fontSize:"36px"}}><div style={{marginTop:"20px"}}><div style={{fontWeight:"700",marginTop:"-20px",fontSize:"18px"}}>Critic Score</div><div>{Math.round(details.aggregated_rating)}%</div><div style={{fontSize:"14px"}}>Based on <div>{details.aggregated_rating_count} Critic Reviews</div></div></div></div>
                        }
                        <div className="gameDetailsRO" style={{fontSize:"36px"}}><div style={{marginTop:"20px"}}><div style={{fontWeight:"700",marginTop:"-20px",fontSize:"18px"}}>User Score</div><div>{hltbHours.hltbReviewCount > 0 ? hltbHours.hltbReview+"%" : "N/A"}</div><div style={{fontSize:"14px"}}>Based on <div>{hltbHours.hltbReviewCount} User Reviews</div></div></div></div>
                        <div className="gameDetailsHO" style={{fontSize:"18px"}}><div style={{marginTop:"10px"}}><div style={{fontWeight:"700"}}>Main Story</div><div style={{fontSize:"26px"}}>{hltbHours.mainCount > 0 ? (hltbHours.mainHours / 3600).toFixed(1)+" Hours" : "N/A"}</div><div style={{fontSize:"14px"}}>Based on <div>{hltbHours.mainCount} Submissions</div></div></div></div>
                        <div className="gameDetailsHO" style={{fontSize:"18px"}}><div style={{marginTop:"10px"}}><div style={{fontWeight:"700"}}>Main+Extra</div><div style={{fontSize:"26px"}}>{hltbHours.mainExtraCount > 0 ? (hltbHours.mainExtraHours / 3600).toFixed(1)+" Hours" : "N/A"}</div><div style={{fontSize:"14px"}}>Based on <div>{hltbHours.mainExtraCount} Submissions</div></div></div></div>
                        <div className="gameDetailsHO" style={{fontSize:"18px"}}><div style={{marginTop:"10px"}}><div style={{fontWeight:"700"}}>100 Percent</div><div style={{fontSize:"26px"}}>{hltbHours.completionCount > 0 ? (hltbHours.completionHours / 3600).toFixed(1)+" Hours" : "N/A"}</div><div style={{fontSize:"14px"}}>Based on <div>{hltbHours.completionCount} Submissions</div></div></div></div>
                    </div>
                }
                <br />
                <br />
                {gameWebsites && <div style={{display:"grid",gridTemplateColumns:"auto auto auto",rowGap:"20px",textAlign:"center"}}>{gameWebsites}</div>}
                <br />
                {details.summary && <div><div className="boxTitle">Game Summary</div><div style={{width:"80px",height:"3px",backgroundColor:"red"}} /><div style={{backgroundImage:"linear-gradient(270deg,#a760e9,rgb(71, 0, 137), rgba(0,0,0,1))",borderRadius:"20px",padding:"10px",marginTop:"10px",color:"white"}}>{details.summary}</div></div>}
                <br />
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onClick={()=>setCategory(0)} defaultChecked={true} />
                    <label className="btn btn-primary" htmlFor="btnradio1">Bundles ({gameBundles ? gameBundles.length : 0})</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onClick={()=>setCategory(1)} autoComplete="off" />
                    <label className="btn btn-primary" htmlFor="btnradio2">DLC ({gameDlc ? gameDlc.length : 0})</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onClick={()=>setCategory(2)} autoComplete="off" />
                    <label className="btn btn-primary" htmlFor="btnradio3">Expansions ({(gameExpac && gameStandalone) ? gameExpac.length + gameStandalone.length : (gameExpac && !gameStandalone) ? gameExpac.length : (!gameExpac && gameStandalone) ? gameStandalone.length : 0})</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio4" onClick={()=>setCategory(3)} autoComplete="off" />
                    <label className="btn btn-primary" htmlFor="btnradio4">Remakes/Remasters ({(gameRemakes && gameRemasters) ? gameRemakes.length + gameRemasters.length : gameRemakes ? gameRemakes.length : gameRemasters ? gameRemasters.length : 0})</label>
                </div>
                <div className="gameDetailsGames" style={{fontWeight:"600"}}>{currentPageDetails}</div>
                {details.storyline && <div><div className="boxTitle">Storyline</div><div style={{width:"80px",height:"3px",backgroundColor:"green"}} /><div style={{backgroundImage:"linear-gradient(270deg,#a760e9,rgb(71, 0, 137), rgba(0,0,0,1))",borderRadius:"20px",padding:"10px",marginTop:"10px",color:"white"}}>{details.storyline}</div></div>}
                <br />
                {(gameVideos && gameScreenshots) && <div>
                    <div className="boxTitle">Screenshots & Videos</div><div style={{width:"80px",height:"3px",backgroundColor:"blue"}} />
                    <div className="gameDCaro">
                    <Carousel renderThumbs={customRenderThumb}>
                        {[...gameVideos,...gameScreenshots]}
                    </Carousel>
                    </div>
                </div>}
                {(!gameVideos && gameScreenshots) && <div className="gameDCaro">
                    <Carousel renderThumbs={customRenderThumb}>
                        {gameScreenshots}
                    </Carousel>
                </div>}
                {(gameVideos && !gameScreenshots) && <div className="gameDCaro">
                    <Carousel renderThumbs={customRenderThumb}>
                        {gameVideos}
                    </Carousel>
                </div>}
            </div>
            <div>
            {gameSimilar && <div><div className="boxTitle">Similar Games</div><div style={{width:"80px",height:"3px",backgroundColor:"purple"}} /><div className="similarGames" style={{fontWeight:"600"}}>{gameSimilar}</div></div>}
            </div>
        </div>
        }
        </>
    )
}

export default GameDetails;