import React, { useState, useEffect } from 'react';
import { CalendarHeader } from '../Components/Calendar/CalendarHeader';
import { Day } from '../Components/Calendar/Day';
import { useDate } from '../Hooks/useDate';
import { DayEventListModal } from '../Components/Modals/DayEventListModal';
import { apiToken } from '../Utils/apiToken';
import { CurrentDayEventList } from '../Components/EventList/CurrentDayEventList';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Countdown from '../Components/Countdown';

export const Home = ({userData,onUserDataSave,onUserListUpdate,events,onEventSave,onEventDel}) => {
  const navigate = useNavigate()
  const [nav, setNav] = useState(0);
  const [sideListData, setSideListData] = useState([])
  const [clicked, setClicked] = useState();
  const [updating,setUpdating] = useState();
  useEffect(() => { setSideListData([mapSideList(0),mapSideList(1),mapSideList(2),mapSideList(5)]) }, [userData]);
  const { days, dateDisplay } = useDate(events, nav);  
  const [countdownEvents,setCountdownEvents] = useState(localStorage.getItem("cds") ? JSON.parse(localStorage.getItem("cds")) : [0,0,0,0,0,0])
  useEffect(() => { localStorage.setItem('cds', JSON.stringify(countdownEvents)) }, [countdownEvents])
  apiToken();

  const getXboxData = async () => {
    const gameIds = await axios.get("https://game-cal-cors.onrender.com/https://catalog.gamepass.com/sigls/v2?id=fdd9e2a7-0fee-49f6-ad69-4354098401ff&language=en-us&market=US")
        .then(response => response.data.slice(1).map((item)=> item.id))
    const eaGameIds = await axios.get("https://game-cal-cors.onrender.com/https://catalog.gamepass.com/sigls/v2?id=b8900d09-a491-44cc-916e-32b5acae621b&language=en-us&market=US")
        .then(response => response.data.slice(1).map((item)=> item.id))
    const xboxGames = await axios.get(`https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${gameIds}&market=US&languages=en-us`)
        .then((response) => response.data.Products.map((item)=>{
            return({
                title: item.LocalizedProperties[0]?.ProductTitle,
                id: item.ProductId,
                userRating: item.MarketProperties[0]?.UsageData[2]?.AverageRating,
                price: item.DisplaySkuAvailabilities[0]?.Availabilities[0]?.OrderManagementData.Price.ListPrice,
                type: "xbox"
            })
        }))
    const eaGames = await axios.get(`https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${eaGameIds}&market=US&languages=en-us`)
        .then((response) => response.data.Products.map((item)=>{
            return({
                title: item.LocalizedProperties[0]?.ProductTitle,
                id: item.ProductId,
                userRating: item.MarketProperties[0]?.UsageData[2]?.AverageRating,
                price: item.DisplaySkuAvailabilities[0]?.Availabilities[0]?.OrderManagementData.Price.ListPrice,
                type: "ea"
            })
        }))
    localStorage.setItem("gamePassData",JSON.stringify({lastUpdate: new Date().getTime(), data:[...xboxGames,...eaGames]}));
    let temp = [...userData]
    temp.map((item, ind) => {
        let game = [...xboxGames,...eaGames].find((e) => e.title.replace(/[!'.,-:]/g, "").toLowerCase().includes(item.game.name.replace(/[!'.,-:]/g, "").toLowerCase()));
        if(game) {
            if(game.type === "xbox")
                temp[ind].onGamePass = true;
            else if(game.type === "ea")
                temp[ind].onEAPlay = true;
        }
        else {
            temp[ind].onGamePass = false;
            temp[ind].onEAPlay = false;
        }
    })
    onUserListUpdate(temp);
  }

  if(!localStorage.getItem("gamePassData") || new Date().getTime() - JSON.parse(localStorage.getItem("gamePassData")).lastUpdate > 86400000)
    getXboxData();

  const mapSideList = (category) => {
    return userData.filter((e) => e.category === category).sort((a,b) => {
      if (a["date_added"] > b["date_added"]) {
          return -1;
      }}).slice(0, 5)
      .map((e, index) => {
          return(
            <li key={index} className="sUListItem" style={{display:"grid",gridTemplateColumns:"auto auto",height:"75px",fontWeight:"600",justifyContent:"space-between",backgroundImage:e.game.cover && `linear-gradient(to left, rgb(0, 0, 0,0.1), rgba(0,0,0,0.6), black),url(//images.igdb.com/igdb/image/upload/t_1080p/${e.game.cover.image_id}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"center"}}>
              <a onClick={()=>navigate("/game-details/"+e.game.id)} style={{display:"grid",gridTemplateColumns:"auto auto",justifyContent:"start",columnGap:"5px", cursor:"pointer"}}>
                <span style={{margin:"auto"}}>{e.game.name}</span>
              </a>
                {(e.category === 0 || e.category === 2) && <div style={{margin:"auto",textAlign:"right"}}>{e.user_hours ? e.user_hours : 0} Hours Played {e.category === 2 && <div>{e.user_rating ? e.user_rating : "NA"}/10</div>}</div>}
            </li>
          )
    })
  }

  const updateDates = () => {
    setUpdating("Updating...");
    let search = ``
    events.map((e) => {
      if(e.game && (new Date().getTime()-new Date(e.date).getTime() < 15778476000))
        search === `` ? search = e.game.id.toString() : search += ","+e.game.id.toString();
    })
    if(search) {
      axios({
          url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Client-ID': JSON.parse(localStorage.getItem('client_id')),
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
          },
              data: `fields first_release_date; limit `+events.length+`; where id = (`+search+`);`
          })
          .then(response => {
              let tempEvents = [...events]
              let tempUserList = [...userData]
              let respCopy = response.data.slice()
              respCopy.map((item) => {
                let date = item.first_release_date ? new Date(item.first_release_date*1000) : new Date("12-31-"+new Date(Date.now()).getFullYear());
                if(date.getTime() >= new Date().getTime()) {
                  let eventIndex = tempEvents.findIndex((e)=> e.game && e.game.id === item.id)
                  let gameIndex = tempUserList.findIndex((e)=> e.game && e.game.id === item.id)
                  if(date && !tempEvents[eventIndex].custom)
                    tempEvents[eventIndex].date = `${date.getUTCMonth()+1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
                  if(gameIndex !== -1)
                    tempUserList[gameIndex].game.first_release_date = item.first_release_date;
                }
              })
              onEventSave(tempEvents)
              onUserDataSave(tempUserList)
              search = ``
              setUpdating("Upcoming Event Game Release Dates Updated.")
          })
          .catch(err => {
            setUpdating("Failed To Update.");
            console.error(err);
      });
    }
  }

  const saveCountdowns = (newEvent, ind) => {
    let cdEventCopy = countdownEvents.slice()
    if(newEvent)
      cdEventCopy[ind] = events.find((event) => event.title === newEvent);
    else
      cdEventCopy[ind] = 0;
    setCountdownEvents(cdEventCopy);
  }

  return(
    <>
      <div className="standinBg" />
      <div id="col-grid-container">
        <div style={{display:"grid",gridTemplateColumns:"auto",height:"fit-content",justifyContent:"flex-end",marginRight:"25px"}}>
          <div>
            <div style={{display:"flex"}}>
              <div style={{paddingRight:"10px"}} className="boxTitle">Currently Playing</div>
              {sideListData[0] && sideListData[0].length > 0 && <a className="uListLink" style={{fontSize:"18px",marginTop:"10px"}} onClick={() => {navigate("/user-profile", {state: 0})}}>See All</a>}
            </div>
            <div className="homeSepLine" style={{backgroundColor:"red"}} />
            <div className="sUListBg">
              <ul id="uList" style={{backgroundImage:"linear-gradient(to right, black, rgba(0, 0, 0, 0.1)"}}>
                {sideListData[0]}
              </ul>
              {sideListData[0] && sideListData[0].length === 0 && <div>No Games Found! Add some to the list!</div>}
            </div>
          </div>
          <div>
            <div style={{display:"flex"}}>
              <div style={{paddingRight:"10px"}} className="boxTitle">Completed</div>
              {sideListData[2] && sideListData[2].length > 0 && <a className="uListLink" style={{fontSize:"18px",marginTop:"10px"}} onClick={() => {navigate("/user-profile", {state: 2})}}>See All</a>}
            </div>
            <div className="homeSepLine" style={{backgroundColor:"blue"}} />
            <div className="sUListBg">
              <ul id="uList">
                {sideListData[2]}
              </ul>
              {sideListData[2] && sideListData[2].length === 0 && <div>No Games Found! Add some to the list!</div>}
            </div>
          </div>
          <div>
            <div style={{display:"flex"}}>
              <div style={{paddingRight:"10px"}} className="boxTitle">Planning to Play</div>
              {sideListData[1] && sideListData[1].length > 0 && <a className="uListLink" style={{fontSize:"18px",marginTop:"10px"}} onClick={() => {navigate("/user-profile", {state: 1})}}>See All</a>}
            </div>
            <div className="homeSepLine" style={{backgroundColor:"green"}} />
            <div className="sUListBg">
              <ul id="uList">
                {sideListData[1]}
              </ul>
              {sideListData[1] && sideListData[1].length === 0 && <div>No Games Found! Add some to the list!</div>}
            </div>
          </div>
          <div>
            <div style={{display:"flex"}}>
              <div style={{paddingRight:"10px"}} className="boxTitle">Watchlist</div>
              {sideListData[3] && sideListData[3].length > 0 && <a className="uListLink" style={{fontSize:"18px",marginTop:"10px"}} onClick={() => {navigate("/user-profile", {state: 5})}}>See All</a>}
            </div>
            <div className="homeSepLine" style={{backgroundColor:"gold"}} />
            <div className="sUListBg">
              <ul id="uList">
                {sideListData[3]}
              </ul>
              {sideListData[3] && sideListData[3].length === 0 && <div>No Games Found! Add some to the list!</div>}
            </div>
          </div>
        </div>
        <div id="calContainer">
          <div style={{backgroundColor:"#212529",paddingTop:"5px",borderRadius:"20px",position:"relative"}}>
            <CalendarHeader 
              dateDisplay={dateDisplay}
              onNav={year => setNav(year)}
              onNext={() => setNav(nav + 1)}
              onBack={() => setNav(nav - 1)}
            />
            <div id="calendar">
              <div id="weekdays">Sunday</div>
              <div id="weekdays">Monday</div>
              <div id="weekdays">Tuesday</div>
              <div id="weekdays">Wednesday</div>
              <div id="weekdays">Thursday</div>
              <div id="weekdays">Friday</div>
              <div id="weekdays">Saturday</div>
              {days.map((d, index) => (
                <Day
                  key={index}
                  day={d}
                  onClick={() => {
                    if (d.value !== 'padding') {
                      setClicked(d.date);
                    }
                  }}
                />
              ))}
            </div>
            {clicked &&
              <DayEventListModal
                eventDate={clicked}
                events={events}
                onClose={() => setClicked(null)}
                onDelete={title => {  
                  onEventDel(events.filter(e => e.title !== title)); setClicked(null)}}
                onSave={e => onEventSave([ ...events, { title: e.title, game: e.game, date: clicked, custom: true }])}
              />
            }
          </div>
          {updating && <div style={{color:"white",textShadow:"1px 1px 1px #a760e9"}}>{updating}</div>}
          <CurrentDayEventList 
            updateDates={() => updateDates()}
            events={events}
            onDelete={title => {onEventDel(events.filter(e => e.title !== title)); setClicked(null)}}
          />
        </div>
        <div style={{display:"grid", scale:"0.85",height:"fit-content", gap:"10px"}}>
          {countdownEvents.map((e, ind) => <Countdown key={ind} events={events} selectedEvent={countdownEvents[ind]} addCountdown={(e) => saveCountdowns(e, ind)} />)}
        </div>
      </div>
    </>
  );
};
export default Home;