import axios from "axios";
import { useState } from "react";

export const apiDefaultGrab = () => {
    const [genreData] = useState(localStorage.getItem('genreData') ? JSON.parse(localStorage.getItem('genreData')) : null);
    const [themeData] = useState(localStorage.getItem('themeData') ? JSON.parse(localStorage.getItem('themeData')) : null);
    const [perspectiveData] = useState(localStorage.getItem('perspectiveData') ? JSON.parse(localStorage.getItem('perspectiveData')) : null);
    const [modeData] = useState(localStorage.getItem('modeData') ? JSON.parse(localStorage.getItem('modeData')) : null);
    const [statusData] = useState(localStorage.getItem('statusData') ? JSON.parse(localStorage.getItem('statusData')) : null);

    if(!genreData) {
        axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/genres",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields name; limit 25;`
            })
            .then(response => {
                localStorage.setItem('genreData', JSON.stringify(response.data))
            })
            .catch(err => {
                console.error(err);
            });
    }
    if(!themeData) {
        axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/themes",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields name; limit 25;`
            })
            .then(response => {
                localStorage.setItem('themeData', JSON.stringify(response.data))
            })
            .catch(err => {
                console.error(err);
            });
    }
    if(!perspectiveData) {
        axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/player_perspectives",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields name; limit 100;`
            })
            .then(response => {
                localStorage.setItem('perspectiveData', JSON.stringify(response.data))
            })
            .catch(err => {
                console.error(err);
            });
    }
    if(!modeData) {
        axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/game_modes",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields name; limit 100;`
            })
            .then(response => {
                localStorage.setItem('modeData', JSON.stringify(response.data))
            })
            .catch(err => {
                console.error(err);
            });
    }
    if(!statusData) {
        axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/game_statuses",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields status; limit 100;`
            })
            .then(response => {
                localStorage.setItem('statusData', JSON.stringify(response.data))
            })
            .catch(err => {
                console.error(err);
            });
    }
    return {
        genreData,
        themeData,
        perspectiveData,
        modeData,
        statusData
    }
}