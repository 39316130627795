import { apiDefaultGrab } from "../Utils/apiDefaultGrab";

export const SearchFilters = ({currentFilters,addToSearch,removeFromSearch,andOrCheck,setAndOrCheck}) => {
    const {genreData,themeData,perspectiveData,modeData, statusData} = apiDefaultGrab()
    return (
        <>
            <div style={{display:"grid",rowGap:"10px"}}>
                <div style={{display:"flex", gap:"20px",paddingTop:"10px"}}>
                    <div className="boxTitle" style={{fontSize:"24px"}}>Platforms</div>
                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradioandor" id="btnradioand" autoComplete="off" onChange={() => setAndOrCheck(2)} checked={andOrCheck == 2} />
                        <label className="btn btn-primary btn-sm" htmlFor="btnradioand">And</label>
                        <input type="radio" className="btn-check" name="btnradioandor" id="btnradioor" autoComplete="off" onChange={() => setAndOrCheck(1)} checked={andOrCheck == 1} />
                        <label className="btn btn-primary btn-sm" htmlFor="btnradioor">Or</label>
                    </div>
                </div>
                <div style={{width:"80px",height:"3px",backgroundColor:"red"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("PC (Microsoft Windows)") ? addToSearch({search:6,searchName:"PC (Microsoft Windows)",type:"platforms"}) : removeFromSearch("PC (Microsoft Windows)")}} >PC (Microsoft Windows)</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Playstation 5") ? addToSearch({search:167,searchName:"Playstation 5",type:"platforms"}) : removeFromSearch("Playstation 5")}} >Playstation 5</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Xbox Series X|S") ? addToSearch({search:169,searchName:"Xbox Series X|S",type:"platforms"}) : removeFromSearch("Xbox Series X|S")}} >Xbox Series X|S</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Nintendo Switch") ? addToSearch({search:130,searchName:"Nintendo Switch",type:"platforms"}) : removeFromSearch("Nintendo Switch")}} >Nintendo Switch</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Playstation 4") ? addToSearch({search:48,searchName:"Playstation 4",type:"platforms"}) : removeFromSearch("Playstation 4")}} >Playstation 4</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Playstation 3") ? addToSearch({search:9,searchName:"Playstation 3",type:"platforms"}) : removeFromSearch("Playstation 3")}} >Playstation 3</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Xbox One") ? addToSearch({search:49,searchName:"Xbox One",type:"platforms"}) : removeFromSearch("Xbox One")}} >Xbox One</div>
                    <div className="sFilterName" onClick={()=>{!currentFilters.includes("Xbox 360") ? addToSearch({search:12,searchName:"Xbox 360",type:"platforms"}) : removeFromSearch("Xbox 360")}} >Xbox 360</div>
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Release Status</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"lightBlue"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {statusData ? statusData.map((e,index) => {if(e.status == "Released" || e.status == "Early Access") return(<div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.status) ? addToSearch({search:e.id,searchName:e.status,type:"release_status"}) : removeFromSearch(e.status)}}>{e.status}</div>)}) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Game Modes</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"orange"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {modeData ? modeData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"game_modes"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Genres</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"yellow"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px",overflowY:"auto",height:"200px"}}>
                    {genreData ? genreData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"genres"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Player Perspectives</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"green"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {perspectiveData ? perspectiveData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"player_perspectives"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
                <div className="boxTitle" style={{fontSize:"24px"}}>Themes</div>
                <div style={{width:"80px",height:"3px",backgroundColor:"blue"}} />
                <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                    {themeData ? themeData.map((e,index) => <div key={index} className="sFilterName" onClick={()=>{!currentFilters.includes(e.name) ? addToSearch({search:e.id,searchName:e.name,type:"themes"}) : removeFromSearch(e.name)}}>{e.name}</div>) : null}
                </div>
            </div>
        </>
    )
}

export default SearchFilters;