import { useEffect, useState } from "react";
import Rating from "react-rating";

export const UserListButtons = ({onUserListDel,onUserListSave,userData, events, onEventSave, game, userListCat, searchBar, searchResult, steamGrab, gamePassData}) => {
    const steamGame = (steamGrab && steamGrab.length > 0) && steamGrab.find(g => g.name.toLowerCase() === game.name.toLowerCase());
    const [userHours,setUserHours] = useState(steamGame ? (steamGame.playtime_forever/60).toFixed(1) : "")
    const [userYear,setUserYear] = useState(null)
    const [userRating,setUserRating] = useState(null)
    const [saveCat,setSaveCat] = useState();
    const [errMessage,setErrMessage] = useState();
    const [checkList,setCheckList] = useState(false);
    const [checkCategory,setCheckCategory] = useState(userListCat >= 0 ? userListCat : -1);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(userData) {
            const gameData = userData.find(gameData => gameData.game.id === game.id);
            setCheckList(gameData ? gameData : false);
            setCheckCategory(gameData ? gameData.category : -1);
        }
    },[userData,game])

    const validateYear = (e) => {
        e.preventDefault()
        if(userYear) {
            if(userYear >= 1900 && userYear <= new Date().getFullYear()) {
                setErrMessage()
                setUserYear(userYear)
                saveGame()
                setUserYear()
            }
            else
                setErrMessage("Year must be between 1900 and the current year")
        } else {
            saveGame()
        }
    }

    const saveEvent = (title,date) => {
        const dateChange = `${date.getUTCMonth()+1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
        onEventSave([ ...events, { title: title+" Release", game: game, date: dateChange }])
    }

    const saveGame = (cat) => {
        if (!userHours) setUserHours(0)
        setLoading(true);
        fetch(`https://game-cal-cors.onrender.com/https://shouldiplay-api.up.railway.app/hltb/${encodeURIComponent(game.name.toLowerCase())}`)
        .then((response) => response.json())
        .then((result) => {
            const hltbHours = result.data.map((e)=>{
                return({gameName:e.game_name,gameType:e.game_type,mainHours:e.comp_main,mainCount:e.comp_main_count,mainExtraHours:e.comp_plus,mainExtraCount:e.comp_plus_count,completionHours:e.comp_100,completionCount:e.comp_100_count,hltbReview:e.review_score,hltbReviewCount:e.count_review})
            })
            const gameFields = {category:game.category,cover:game.cover,first_release_date:game.first_release_date,game_modes:game.game_modes,genres:game.genres,id:game.id,name:game.name}
            gameFields.game_status = game.game_status && game.game_status
            const gameDataToSave = { category: cat || cat === 0 ? cat : saveCat, game: gameFields, user_hours: userHours, user_rating: userRating, date_added: new Date().getTime(), hltb:hltbHours, last_steam_update: game.last_steam_update && game.last_steam_update, played_year: userYear }
            gameDataToSave.last_steam_update = steamGame ? steamGame.playtime_forever : null;
            gameDataToSave.lastHLTBUpdate = new Date().getTime();
            if(gamePassData) {
                if(gamePassData.type === "xbox")
                    gameDataToSave.onGamePass = true;
                else if(gamePassData.type === "ea")
                    gameDataToSave.onEAPlay = true;
            }
            onUserListSave(gameDataToSave);
            if(new Date(game.first_release_date*1000).getTime() > new Date().getTime())
                saveEvent(game.name,new Date(game.first_release_date*1000))
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            const hltbHours = [];
            const gameFields = {category:game.category,cover:game.cover,first_release_date:game.first_release_date,game_modes:game.game_modes,genres:game.genres,id:game.id,name:game.name}
            gameFields.game_status = game.game_status && game.game_status
            const gameDataToSave = { category: cat || cat === 0 ? cat : saveCat, game: gameFields, user_hours: userHours, user_rating: userRating, date_added: new Date().getTime(), hltb:hltbHours, last_steam_update: game.last_steam_update && game.last_steam_update, played_year: userYear }
            gameDataToSave.last_steam_update = steamGame ? steamGame.playtime_forever : null;
            gameDataToSave.lastHLTBUpdate = new Date().getTime();
            if(gamePassData) {
                if(gamePassData.type === "xbox")
                    gameDataToSave.onGamePass = true;
                else if(gamePassData.type === "ea")
                    gameDataToSave.onEAPlay = true;
            }
            onUserListSave(gameDataToSave);
            if(new Date(game.first_release_date*1000).getTime() > new Date().getTime())
                saveEvent(game.name,new Date(game.first_release_date*1000))
            setLoading(false);
        })
    }

    const deleteGame = (game) => {
        onUserListDel(game);
        setCheckList(false);
        setCheckCategory(-1);
    }

    return (
        <>
            {!loading ?
                <div> 
                    {!searchBar && <div>
                        {!checkList && 
                            <form onSubmit={validateYear}>
                                {!searchResult && <div className="gameDInfoBorder">
                                    {errMessage &&
                                        <div>
                                            <i style={{color:"red"}}>{errMessage}</i>
                                        </div>
                                    }
                                    <div>
                                        Year Played:
                                        <input
                                            type="number"
                                            placeholder="Year..."
                                            maxLength={4}
                                            value={userYear}
                                            onChange={(e) => {setUserYear(e.target.value)}}
                                            style={{width:"80px",marginLeft:"10px"}}
                                        />
                                    </div>
                                    <div>
                                        Your Hours Played:
                                        <input
                                            type="number"
                                            placeholder="Hours..."
                                            value={userHours}
                                            onChange={(e) => {setUserHours(e.target.value)}}
                                            style={{width:"80px",marginLeft:"10px"}}
                                        />
                                    </div>
                                    <div>
                                        Your Rating: 
                                        <Rating
                                            stop={10}
                                            fractions={2}
                                            onChange={(value) => {setUserRating(value)}}
                                            emptySymbol="fa fa-star-o fa-2x"
                                            fullSymbol="fa fa-star fa-2x"
                                            style={{color:"gold",fontSize:"11px",marginLeft:"10px"}}
                                        />
                                    </div>
                                </div>}
                                {<div id="gDUListBtn">
                                    <button className="btn btn-primary btn-sm" onClick={() => setSaveCat(0)}>Add to 'Currently Playing'</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => setSaveCat(1)}>Add to 'Planning to Play'</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => setSaveCat(2)}>Add to 'Completed'</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => setSaveCat(3)}>Add to 'On Hold'</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => setSaveCat(4)}>Add to 'Dropped'</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => setSaveCat(5)}>Add to 'Watchlist'</button>
                                </div>}
                            </form>
                        }
                        {(checkList || checkCategory > -1) &&
                            <div id="gDUListBtn">
                                {checkCategory === 0 && <button className="btn btn-danger btn-sm" onClick={()=> {deleteGame(game)}}>Remove from 'Currently Playing'</button>}
                                {checkCategory === 1 && <button className="btn btn-danger btn-sm" onClick={()=> {deleteGame(game)}}>Remove from 'Planning to Play'</button>}
                                {checkCategory === 2 && <button className="btn btn-danger btn-sm" onClick={()=> {deleteGame(game)}}>Remove from 'Completed'</button>}
                                {checkCategory === 3 && <button className="btn btn-danger btn-sm" onClick={()=> {deleteGame(game)}}>Remove from 'On Hold'</button>}
                                {checkCategory === 4 && <button className="btn btn-danger btn-sm" onClick={()=> {deleteGame(game)}}>Remove from 'Dropped'</button>}
                                {checkCategory === 5 && <button className="btn btn-danger btn-sm" onClick={()=> {deleteGame(game)}}>Remove from 'Watchlist'</button>}
                            </div>
                        }
                    </div>
                    }
                    {searchBar &&
                    <div>
                        {!checkList && checkCategory < 0 &&
                            <div id="sUListBtn" style={{borderRadius:"10px",display:"flex",flexWrap:"wrap"}}>
                                <button className="btn" onClick={() => saveGame(0)} title="Add to 'Currently Playing'"><i className="fa fa-gamepad" style={{color:"#a760e9",textShadow:"2px 1px 1px #0d6efd"}} aria-hidden="true" /></button>
                                <button className="btn" onClick={() => saveGame(1)} title="Add to 'Planning to Play'"><i className="fa fa-book" style={{color:"#a760e9",textShadow:"2px 1px 1px #0d6efd"}} aria-hidden="true" /></button>
                                <button className="btn" onClick={() => saveGame(2)} title="Add to 'Completed'"><i className="fa fa-trophy" style={{color:"#a760e9",textShadow:"2px 1px 1px #0d6efd"}} aria-hidden="true" /></button>
                                <button className="btn" onClick={() => saveGame(3)} title="Add to 'On Hold'"><i className="fa fa-pause" style={{color:"#a760e9",textShadow:"2px 1px 1px #0d6efd"}} aria-hidden="true" /></button>
                                <button className="btn" onClick={() => saveGame(4)} title="Add to 'Dropped'"><i className="fa fa-ban" style={{color:"#a760e9",textShadow:"2px 1px 1px #0d6efd"}} aria-hidden="true" /></button>
                                <button className="btn" onClick={() => saveGame(5)} title="Add to 'Watchlist'"><i className="fa fa-list-ul" style={{color:"#a760e9",textShadow:"2px 1px 1px #0d6efd"}} aria-hidden="true" /></button>
                            </div>
                        }
                        {(checkList || checkCategory > -1) &&
                            <div id="sUListBtn" style={{gridTemplateColumns:"auto"}}>
                                {checkCategory === 0 && <button className="btn btn-danger" onClick={()=> {deleteGame(game)}} title="Remove from 'Currently Playing'"><i className="fa fa-gamepad" aria-hidden="true" /></button>}
                                {checkCategory === 1 && <button className="btn btn-danger" onClick={()=> {deleteGame(game)}} title="Remove from 'Planning to Play'"><i className="fa fa-book" aria-hidden="true" /></button>}
                                {checkCategory === 2 && <button className="btn btn-danger" onClick={()=> {deleteGame(game)}} title="Remove from 'Completed'"><i className="fa fa-trophy" aria-hidden="true" /></button>}
                                {checkCategory === 3 && <button className="btn btn-danger" onClick={()=> {deleteGame(game)}} title="Remove from 'On Hold'"><i className="fa fa-pause" aria-hidden="true" /></button>}
                                {checkCategory === 4 && <button className="btn btn-danger" onClick={()=> {deleteGame(game)}} title="Remove from 'Dropped'"><i className="fa fa-ban" aria-hidden="true" /></button>}
                                {checkCategory === 5 && <button className="btn btn-danger" onClick={()=> {deleteGame(game)}} title="Remove from 'Watchlist'"><i className="fa fa-list-ul" aria-hidden="true" /></button>}
                            </div>
                        }
                    </div>
                    }
                </div>
            :
                <div style={{color:"white",textShadow:"2px 2px 2px black",textAlign:"center",fontSize:"20px"}}>Saving...</div>
            }
        </>
    )
}

export default UserListButtons;